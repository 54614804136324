import { Module } from 'vuex'
import {
  IDashboardAffiliationState,
  dashboardAffiliationStates as state,
} from './states'
import { dashboardAffiliationActions as actions } from './actions'
import { dashboardAffiliationGetters as getters } from './getters'
import { dashboardAffiliationMutations as mutations } from './mutations'

const dashboardAffiliationModule: Module<IDashboardAffiliationState, {}> = {
  state,
  getters,
  mutations,
  actions,
}

export default dashboardAffiliationModule
