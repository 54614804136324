export const affiliateColumn = [
  {
    title: 'Nom',
    dataIndex: 'affiliated',
    key: 'firstname',
    sorter: true,
    columnName: 'firstname',
    slots: { customRender: 'firstname', filterIcon: 'filterIcon' },
  },
  {
    title: 'Prénom',
    dataIndex: 'affiliated',
    key: 'lastname',
    sorter: true,
    columnName: 'lastname',
    slots: { customRender: 'lastname', filterIcon: 'filterIcon' },
  },
  {
    title: 'Email',
    dataIndex: 'affiliated',
    key: 'email',
    sorter: true,
    width: 180,
    columnName: 'email',
    slots: { customRender: 'email', filterIcon: 'filterIcon' },
  },
  {
    title: 'Téléphone',
    dataIndex: 'affiliated',
    key: 'phone',
    sorter: true,
    columnName: 'phone',
    slots: { customRender: 'phone', filterIcon: 'filterIcon' },
  },
  {
    title: "Date d'entrée",
    dataIndex: 'affiliated',
    key: 'created',
    align: 'center',
    sorter: true,
    columnName: 'created',
    slots: { customRender: 'created', filterIcon: 'filterIcon' },
  },
  {
    title: 'RIB',
    dataIndex: 'affiliated',
    key: 'iban',
    sorter: true,
    width: 200,
    columnName: 'iban',
    slots: { customRender: 'iban', filterIcon: 'filterIcon' },
  },
  {
    title: '# de sessions générées',
    dataIndex: 'record',
    key: 'nb_session',
    align: 'center',
    width: 100,
    slots: { customRender: 'nb_session', filterIcon: 'filterIcon' },
  },
  {
    title: 'Souscription(s) freemium',
    dataIndex: 'record',
    key: 'subscribe_freemium',
    align: 'center',
    width: 100,
    slots: { customRender: 'subscribe_freemium', filterIcon: 'filterIcon' },
  },
  {
    title: 'Souscription(s) payée(s)',
    dataIndex: 'record',
    key: 'subscribe',
    align: 'center',
    width: 100,
    slots: { customRender: 'subscribe', filterIcon: 'filterIcon' },
  },
  {
    title: "Gains depuis l'entrée",
    dataIndex: 'record',
    align: 'center',
    key: 'gains',
    width: 100,
    slots: { customRender: 'gains', filterIcon: 'filterIcon' },
  },
  {
    title: '',
    dataIndex: 'record',
    align: 'right',
    key: 'actions',
    width: 10,
    slots: { customRender: 'actions', filterIcon: 'filterIcon' },
  },
]
