import { IDashboardAffiliationData } from '@/intefaces/dashboardAffiliation'
import { IColumn } from '@/intefaces/common'

export interface IDashboardAffiliationState {
  statisticData: IColumn<IDashboardAffiliationData>
}

export const dashboardAffiliationStates: IDashboardAffiliationState = {
  statisticData: {
    columns: [],
    datas: {
      history: {
        details: [],
      },
    },
  },
}
