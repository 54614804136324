import axios from 'axios'
import qs from 'qs'
import localforage from 'localforage'
import { IUserInfo } from '@/intefaces/login'
import router from '@/routes'

axios.defaults.baseURL =
  process.env.VUE_APP_BASE_URL + process.env.VUE_APP_API_VERSION

function useRequest() {
  axios.interceptors.request.use(async (config) => {
    const data = await localforage.getItem<IUserInfo>('userInfo')

    if (data) {
      if (config.url !== 'login') {
        config.headers['Authorization'] = `Bearer ${data.authorization}`
      }

      if (config.method === 'get') {
        if (config.params) {
          if (data.affiliate_id) {
            config.params['affiliate_id'] = data.affiliate_id
          } else {
            config.params['reseller_id'] = data.reseller_id
          }
        }
      } else {
        if (data.affiliate_id) {
          config.data = `${config.data}&affiliate_id=${data.affiliate_id}`
        } else {
          config.data = `${config.data}&reseller_id=${data.reseller_id}`
        }
      }
    }

    return config
  })

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      if (error.response.status === 401) {
        await localforage.removeItem('userInfo')
        router.push('/login')
      }
    }
  )

  return {
    async get<T>(url: string, params?: { [key: string]: unknown }): Promise<T> {
      const { data } = await axios.get(url, {
        params: params || {},
      })
      return data || {}
    },
    async post(url: string, params: { [key: string]: unknown }) {
      const encodedParams = qs.stringify(params)
      const { data } = await axios.post(url, encodedParams)
      return data
    },
    async put(url: string, params: { [key: string]: unknown }) {
      const encodedParams = qs.stringify(params)
      return await axios.put(url, encodedParams)
    },
  }
}

export const request = useRequest()
