import { MutationTree } from 'vuex'
import { IDashboardResellerState } from './states'
import { setMutationType } from '@/stores'

export const dashboardResellerMutationTypes = {
  SET_DASHBOARD_DATA: 'SET_DASHBOARD_DATA',
  SET_DATA_PAY: 'SET_DATA_PAY',
  SET_AFFILIATE_LIST: 'SET_AFFILIATE_LIST',
  SET_PRODUCT_DATA_LIST: 'SET_PRODUCT_DATA_LIST',
  SET_DISPLAY_AFFILIATE: 'SET_DISPLAY_AFFILIATE',
  SET_RESELLER_SESSION: 'SET_RESELLER_SESSION',
}

const mutations = setMutationType<IDashboardResellerState>(
  dashboardResellerMutationTypes
)

export const dashboardResellerMutations: MutationTree<IDashboardResellerState> = {
  ...mutations('SET_DASHBOARD_DATA', 'dataHistory'),
  ...mutations('SET_DATA_PAY', 'dataPay'),
  ...mutations('SET_AFFILIATE_LIST', 'dataAffiliate'),
  ...mutations('SET_PRODUCT_DATA_LIST', 'dataProduct'),
  ...mutations('SET_DISPLAY_AFFILIATE', 'showAffiliate'),
  ...mutations('SET_RESELLER_SESSION', 'dataResellerSession'),
}
