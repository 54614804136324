import { ActionTree } from 'vuex'
import { v4 } from 'uuid'
import { IDashboardResellerState } from '../states'
import {
  getAffiliationList,
  getCommissions,
  getLastCommissions,
  getCurrentCommissions,
} from '@/services/resellers'
import { dashboardResellerMutationTypes } from '../mutations'
import { dashboardResellerColumn } from './columns'
import { dashboardAffiliationColumn } from '../../dashboardAffiliationModule/mocks/statistics/columns'
import {
  IAffiliatesData,
  IDashboardResellerData,
  IDashboardResellerListDetails,
} from '@/intefaces/dashboardReseller'
import { ISortParams } from '@/intefaces/common'

export const statisticsActions: ActionTree<IDashboardResellerState, any> = {
  setDisplayAffiliate({ commit, state }, value) {
    commit(dashboardResellerMutationTypes.SET_DASHBOARD_DATA, {
      dataHistory: {
        ...changeAffiliationDisplay(
          (state.dataHistory as unknown) as IDashboardResellerData,
          value
        ),
        ...state.dataHistory,
      },
    })
    // handle display affiliiate
    commit(dashboardResellerMutationTypes.SET_DISPLAY_AFFILIATE, {
      showAffiliate: value,
    })
  },
  async setResellerCommissionHistory({ commit, state }, params: ISortParams) {
    try {
      const result = await getCommissions<IDashboardResellerData>(params)
      commit(dashboardResellerMutationTypes.SET_DASHBOARD_DATA, {
        dataHistory: {
          ...reformateDataHistory(result, state.showAffiliate),
          columns: dashboardResellerColumn,
          affiliationColumns: dashboardAffiliationColumn,
        },
      })
    } catch (e) {
      console.error(e)
    }
  },
  async setResellerCommission(
    { commit },
    [params, type = 'lastCommission']: [
      ISortParams,
      'lastCommission' | 'currentCommission'
    ]
  ) {
    const service: {
      [key: string]: any
    } = {
      lastCommission: getLastCommissions,
      currentCommission: getCurrentCommissions,
    }

    try {
      const result = (await service[type](params)) as IDashboardResellerData

      if (result.datas && result.datas.last_month) {
        if (result.datas.last_month.all) {
          result.datas.last_month.all.id = v4()
        }
        if (result.datas.last_month.details) {
          result.datas.last_month.details = result.datas.last_month.details
            .map((detail) => {
              detail.id = v4()
              return detail
            })
            .filter((data) => {
              if (type === 'lastCommission') {
                return data.commission.gains !== 0
              }
              return true
            })
        }
      }

      // we store the type into object
      // to prevent from making a condition
      const currentMutation: {
        [key: string]: {
          mutation: keyof typeof dashboardResellerMutationTypes
          state: keyof IDashboardResellerState
        }
      } = {
        lastCommission: {
          mutation: 'SET_DATA_PAY',
          state: 'dataPay',
        },
        currentCommission: {
          mutation: 'SET_RESELLER_SESSION',
          state: 'dataResellerSession',
        },
      }

      const { mutation, state } = currentMutation[type]

      commit(dashboardResellerMutationTypes[mutation], {
        [state]: {
          ...result,
          columns: dashboardResellerColumn,
          affiliationColumns: dashboardAffiliationColumn,
        },
      })
    } catch (e) {
      console.error(e)
    }
  },
}

function reformateDataHistory(
  data: IDashboardResellerData,
  affiliateVisible: boolean
): IDashboardResellerData {
  const { byMonth } = data.datas
  let tmpData: IDashboardResellerListDetails[] = []
  byMonth?.forEach((month) => {
    tmpData = [
      ...tmpData,
      {
        id: v4(),
        complete_name: '',
        email: '',
        commission: {
          date: month.all?.date,
          visitor: month.all?.visitor as number,
          subscribe: month.all?.subscribe as number,
          subscribe_freemium: month.all?.subscribe_freemium as number,
          gains: month.all?.gains as number,
        },
      },
    ]
    month.details?.forEach((detail) => {
      detail.id = v4()
      detail.affiliate = true
      detail.visible = affiliateVisible
      tmpData = [...tmpData, detail]
    })
  })
  ;(data.datas.byMonth as unknown) = tmpData.filter(
    (data) => data.commission.gains !== 0
  )
  if (data.datas.byYear) {
    data.datas.byYear.id = v4()
  }
  return data
}

function changeAffiliationDisplay(
  data: IDashboardResellerData,
  value: boolean
): IDashboardResellerData {
  data.datas.byMonth = (data.datas
    .byMonth as IDashboardResellerListDetails[])?.map((month) => {
    if (month.affiliate) {
      month.visible = value
    }
    return month
  }) as any
  return data
}
