import { IDashboardResellerState } from '../states'
import { ActionTree } from 'vuex'
import {
  getAffiliationList,
  createAffiliate,
  updateAffiliate,
  updateAffiliateStatus,
} from '@/services/resellers'
import { dashboardResellerMutationTypes } from '../mutations'
import { affiliateColumn } from './columns'
import {
  IAffiliateParams,
  IAffiliatesData,
} from '@/intefaces/dashboardReseller'
import { ISortParams } from '@/intefaces/common'
import { v4 } from 'uuid'

export const affilatesActions: ActionTree<IDashboardResellerState, any> = {
  async setDataAffiliatesList({ commit }, params: ISortParams) {
    try {
      const result = await getAffiliationList<IAffiliatesData>(params)
      if (result.datas && result.datas.result) {
        result.datas.result = result.datas.result.map((result) => {
          result.id = v4()
          return result
        })
      }
      commit(dashboardResellerMutationTypes.SET_AFFILIATE_LIST, {
        dataAffiliate: {
          ...result,
          columns: affiliateColumn,
        },
      })
    } catch (e) {
      console.error(e)
    }
  },
  async setNewAffiliate(_, data: IAffiliateParams) {
    try {
      await createAffiliate(data)
    } catch (e) {
      console.error(e)
    }
  },
  async updateAffiliate(_, data) {
    try {
      await updateAffiliate(data)
    } catch (e) {
      console.error(e)
    }
  },
  async updateAffiliateStatus(_, data) {
    try {
      await updateAffiliateStatus(data)
    } catch (e) {
      console.error(e)
    }
  },
}
