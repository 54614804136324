export const productColumn = [
  {
    title: 'Nom du produit',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    filterIcon: true,
    columnName: 'product_name',
    slots: { customRender: 'name', filterIcon: 'filterIcon' },
  },
  {
    title: 'ID du produit',
    dataIndex: 'id',
    key: 'id',
    sorter: true,
    columnName: 'product_id',
    slots: { customRender: 'id', filterIcon: 'filterIcon' },
  },
  {
    title: 'Type de commission',
    dataIndex: 'commission_type',
    key: 'commission_type',
    align: 'center',
    sorter: true,
    columnName: 'commission_type',
    slots: { customRender: 'commission_type', filterIcon: 'filterIcon' },
  },
  {
    title: 'Commission',
    dataIndex: 'commission',
    key: 'commission',
    align: 'center',
    sorter: true,
    columnName: 'commission',
    slots: { customRender: 'commission', filterIcon: 'filterIcon' },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    align: 'center',
    sorter: true,
    columnName: 'type',
    slots: { customRender: 'type', filterIcon: 'filterIcon' },
  },
  {
    title: 'Frequence',
    dataIndex: 'frequency',
    key: 'frequency',
    align: 'center',
    sorter: true,
    columnName: 'frequency',
    slots: { customRender: 'frequency', filterIcon: 'filterIcon' },
  },
  {
    title: 'Statut',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    sorter: true,
    columnName: 'status',
    slots: { customRender: 'status', filterIcon: 'filterIcon' },
  },

  {
    title: '',
    dataIndex: 'actions',
    align: 'right',
    key: 'actions',
    width: 10,
    slots: { customRender: 'actions', filterIcon: 'filterIcon' },
  },
]
