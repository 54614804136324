export const dashboardResellerColumn = [
  {
    title: 'Affilié',
    dataIndex: 'complete_name',
    key: 'affiliate',
    sorter: true,
    width: 200,
    columnName: 'affiliate',
    slots: { customRender: 'affiliate', filterIcon: 'filterIcon' },
  },
  {
    title: '# de visiteurs générés',
    dataIndex: 'visitor',
    key: 'visitor',
    sorter: true,
    align: 'center',
    width: 200,
    columnName: 'visitor',
    slots: { customRender: 'visitor', filterIcon: 'filterIcon' },
  },
  {
    title: '# de souscription(s) freemium',
    dataIndex: 'subscribe_freemium',
    key: 'subscribe_freemium',
    sorter: true,
    align: 'center',
    width: 200,
    columnName: 'subscribe_freemium',
    slots: { customRender: 'subscribe_freemium', filterIcon: 'filterIcon' },
  },
  {
    title: '# de souscription(s) payée(s)',
    dataIndex: 'subscribe',
    key: 'subscribe',
    sorter: true,
    align: 'center',
    width: 200,
    columnName: 'subscribe',
    slots: { customRender: 'subscribe', filterIcon: 'filterIcon' },
  },
  {
    title: 'Gains',
    dataIndex: 'gains',
    key: 'gains',
    sorter: true,
    align: 'center',
    width: 430 / 4,
    columnName: 'gains',
    slots: { customRender: 'gains', filterIcon: 'filterIcon' },
  },
]
