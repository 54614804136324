import { Directive } from 'vue'

function numberOnly(value: string) {
  if (value) {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }
  return value
}

const thousandFormattedDirective: Directive<any, any> = {
  mounted(el: HTMLElement) {
    el.textContent = numberOnly(el.textContent as string)
  },
  updated(el: HTMLElement) {
    el.textContent = numberOnly(el.textContent as string)
  },
}

export default {
  name: 'thousand',
  handler: thousandFormattedDirective,
}
