import { GetterTree } from 'vuex'
import { IDashboardAffiliationState } from './states'
import { setGetters } from '@/stores'

export const dashboardAffiliationGetters: GetterTree<
  IDashboardAffiliationState,
  any
> = {
  ...setGetters<IDashboardAffiliationState>(
    'getdashboardAffiliation',
    'statisticData'
  ),
}
