import { createApp } from 'vue'
import 'ant-design-vue/dist/antd.css'
import './styles/main.scss'
import App from './App.vue'

// stores
import { store } from './stores'

// router
import router from './routes'

// directives
import thousandFormattedDirective from '@/directives/thousandFormatted'

// antd plugin
import localforage from 'localforage'

const app = createApp(App)

const usedLib = [store, router]
usedLib.forEach((lib) => app.use(lib))

app.directive(
  thousandFormattedDirective.name,
  thousandFormattedDirective.handler
)

let supportIndexDb =
  window.indexedDB ||
  (window as any).mozIndexedDB ||
  (window as any).webkitIndexedDB ||
  (window as any).msIndexedDB

localforage.config({
  driver: supportIndexDb ? localforage.INDEXEDDB : localforage.LOCALSTORAGE,
  name: 'dilypseAffiliation',
  version: 1.0,
  size: 4980736,
  storeName: 'affiliation',
  description: 'storage for affiliation',
})

app.mount('#app')
