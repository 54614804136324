export const dashboardAffiliationColumn = [
  {
    title: 'Période',
    dataIndex: 'date',
    key: 'date',
    sorter: true,
    width: 200,
    slots: { customRender: 'date', filterIcon: 'filterIcon' },
  },
  {
    title: '# de visiteurs générés',
    dataIndex: 'visitor',
    key: 'visitor',
    sorter: true,
    align: 'center',
    width: 200,
    slots: { customRender: 'visitor', filterIcon: 'filterIcon' },
  },
  {
    title: '# de souscription(s) freemium',
    dataIndex: 'subscribe_freemium',
    key: 'subscribe_freemium',
    sorter: true,
    align: 'center',
    width: 200,
    slots: { customRender: 'subscribe_freemium', filterIcon: 'filterIcon' },
  },
  {
    title: '# de souscription(s) payée(s)',
    dataIndex: 'subscribe',
    key: 'subscribe',
    sorter: true,
    align: 'center',
    width: 200,
    slots: { customRender: 'subscribe', filterIcon: 'filterIcon' },
  },
  {
    title: 'Gain(s)',
    dataIndex: 'gains',
    key: 'gains',
    sorter: true,
    align: 'center',
    width: 430 / 4,
    slots: { customRender: 'gains', filterIcon: 'filterIcon' },
  },
]
