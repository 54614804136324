import { IDashboardResellerState } from '../states'
import { ActionTree } from 'vuex'
import {
  getProductsList,
  createProduct,
  updateProductStatus,
} from '@/services/resellers'
import { dashboardResellerMutationTypes } from '../mutations'
import { productColumn } from './columns'
import { IProductParams, IProductData } from '@/intefaces/dashboardReseller'
import { ISortParams } from '@/intefaces/common'

export const productsActions: ActionTree<IDashboardResellerState, any> = {
  async setProductDataList({ commit }, params: ISortParams) {
    try {
      const result = await getProductsList<IProductData>(params)
      commit(dashboardResellerMutationTypes.SET_PRODUCT_DATA_LIST, {
        dataProduct: {
          ...result,
          columns: productColumn,
        },
      })
    } catch (e) {
      console.error(e)
    }
  },
  async setNewProduct({ commit }, data: IProductParams) {
    try {
      const result = await createProduct(data)
    } catch (e) {
      console.error(e)
    }
  },
  async updateProductStatus({ commit }, data: Partial<IProductParams>) {
    try {
      const result = await updateProductStatus(data)
    } catch (e) {
      console.error(e)
    }
  },
}
