import { ActionTree } from 'vuex'
import { ILoginState } from './states'
import { connexion } from '@/services/login'
import { IUserLogin, IUserInfo } from '@/intefaces/login'
import localforage from 'localforage'
import router from '@/routes'
import { loginMutationTypes } from './mutations'

export const loginActions: ActionTree<ILoginState, any> = {
  async setLogin({ commit }, params: IUserLogin) {
    try {
      const result = await connexion(params)
      if (result.code >= 200 && result.code < 400) {
        localforage.setItem('userInfo', result.datas)
        router.push('/')
      } else {
        commit(loginMutationTypes.SET_LOGIN, {
          loginData: {
            code: result.code,
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  },
  async resetLogin({ commit }) {
    commit(loginMutationTypes.SET_LOGIN, {
      loginData: {
        code: 0,
      },
    })
  },
  async setUserInfo({ commit }) {
    const data = await localforage.getItem<IUserInfo>('userInfo')
    commit(loginMutationTypes.USER_INFO, {
      userInfo: data,
    })
  },
}
