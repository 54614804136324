import { MutationTree } from 'vuex'
import { setMutationType } from '@/stores'
import { ILoginState } from './states'

export const loginMutationTypes = {
  SET_LOGIN: 'SET_LOGIN',
  USER_INFO: 'USER_INFO',
}

const mutations = setMutationType<ILoginState>(loginMutationTypes)

export const loginMutations: MutationTree<ILoginState> = {
  ...mutations('SET_LOGIN', 'loginData'),
  ...mutations('USER_INFO', 'userInfo'),
}
