<template>
  <div :class="{ 'layout-container': !isLogin, login: isLogin }">
    <div :class="{ 'content-wrapper': !isLogin }">
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  computed: {
    isLogin(): boolean {
      return this.$router.currentRoute.value.name === 'Login'
    },
  },
})
export default class App extends Vue {}
</script>

<style lang="scss" scoped>
.layout-container {
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  font-family: 'Rubik', Verdana, sans-serif;
}
</style>
