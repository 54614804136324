import {
  IDashboardResellerData,
  IAffiliatesData,
  IProductData,
} from '@/intefaces/dashboardReseller'
import { IColumn } from '@/intefaces/common'

export interface IDashboardResellerState {
  dataPay: IColumn<IDashboardResellerData>
  dataHistory: IColumn<IDashboardResellerData>
  dataAffiliate: IColumn<IAffiliatesData>
  dataProduct: IColumn<IProductData>
  dataResellerSession: IColumn<IProductData>
  showAffiliate: boolean
}

export const dashboardResellerStates: IDashboardResellerState = {
  dataPay: {
    columns: [],
    datas: {
      last_month: {},
    },
  },
  dataHistory: {
    columns: [],
    datas: {
      byMonth: [],
    },
  },
  dataAffiliate: {
    columns: [],
    datas: {
      result: [],
    },
  },
  dataProduct: {
    columns: [],
    datas: [],
  },
  showAffiliate: false,
  dataResellerSession: {
    columns: [],
    datas: [],
  },
}
