
import { Options, Vue } from 'vue-class-component'

@Options({
  computed: {
    isLogin(): boolean {
      return this.$router.currentRoute.value.name === 'Login'
    },
  },
})
export default class App extends Vue {}
