import { IUserLogin } from '@/intefaces/login'

export interface ILoginState {
  loginData: IUserLogin
  userInfo: unknown
}

export const loginStates: ILoginState = {
  loginData: {
    username: '',
    password: '',
  },
  userInfo: {},
}
