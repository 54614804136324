import { ActionTree } from 'vuex'
import { v4 } from 'uuid'

import { dashboardAffiliationColumn } from './mocks/statistics/columns'

import { dashboardAffiliationMutationTypes } from './mutations'
import { IDashboardAffiliationState } from './states'
import { getAffiliates } from '@/services/affiliates'
import { IDashboardAffiliationData } from '@/intefaces/dashboardAffiliation'

export const dashboardAffiliationActions: ActionTree<
  IDashboardAffiliationState,
  any
> = {
  async setDataDashboardAffiliation({ commit }) {
    try {
      const result = await getAffiliates<IDashboardAffiliationData>()
      result.datas.history.details = result.datas.history.details.map(
        (detail) => {
          detail.id = v4()
          return detail
        }
      )
      if (result.datas.history.currentYear) {
        result.datas.history.currentYear.id = v4()
      }
      commit(dashboardAffiliationMutationTypes.SET_DASHBOARD_DATA, {
        statisticData: {
          ...result,
          columns: dashboardAffiliationColumn,
        },
      })
    } catch (e) {
      console.error(e)
    }
  },
}
