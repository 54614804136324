import { Module } from 'vuex'
import {
  IDashboardResellerState,
  dashboardResellerStates as state,
} from './states'
import { dashboardResellerActions as actions } from './actions'
import { dashboardResellerGetters as getters } from './getters'
import { dashboardResellerMutations as mutations } from './mutations'

const dashboardResellerModule: Module<IDashboardResellerState, {}> = {
  state,
  getters,
  mutations,
  actions,
}

export default dashboardResellerModule
