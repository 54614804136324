import { Module } from 'vuex'
import { loginStates as state, ILoginState } from './states'
import { loginActions as actions } from './actions'
import { loginGetters as getters } from './getters'
import { loginMutations as mutations } from './mutations'

const loginModule: Module<ILoginState, {}> = {
  state,
  getters,
  mutations,
  actions,
}

export default loginModule
