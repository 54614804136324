import { GetterTree } from 'vuex'
import { IDashboardResellerState } from './states'
import { setGetters } from '@/stores'

export const dashboardResellerGetters: GetterTree<
  IDashboardResellerState,
  any
> = {
  ...setGetters<IDashboardResellerState>('getDashboardReseller', 'dataHistory'),
  ...setGetters<IDashboardResellerState>('getDashboardResellerPay', 'dataPay'),
  ...setGetters<IDashboardResellerState>('getAffiliationList', 'dataAffiliate'),
  ...setGetters<IDashboardResellerState>('getProductList', 'dataProduct'),
  ...setGetters<IDashboardResellerState>('getShowAffiliate', 'showAffiliate'),
  ...setGetters<IDashboardResellerState>(
    'getResellerSession',
    'dataResellerSession'
  ),
}
