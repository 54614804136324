import { createStore, MutationTree, Commit, ActionTree } from 'vuex'
import dashboardAffiliationModule from './modules/dashboardAffiliationModule'
import dashboardResellerModule from './modules/dashboardResellerModule'
import loginModule from './modules/loginModule'

export const store = createStore({
  modules: {
    dashboardAffiliationModule,
    dashboardResellerModule,
    loginModule,
  },
})

export function setMutationType<T>(mutations: {
  [key: string]: string
}): (type: keyof typeof mutations, key: keyof T) => MutationTree<T> {
  return function mutationsType(type: keyof typeof mutations, key: keyof T) {
    return {
      [mutations[type]](state, payload) {
        state[key] = payload[key]
      },
    }
  }
}

export function setGetters<T>(funcName: string, data: keyof T) {
  return {
    [funcName]: (state: T) => {
      return state[data]
    },
  }
}

export function setActions<T>(mutations: {
  [key: string]: string
}): (
  funcName: string,
  key: keyof typeof mutations,
  dataActions: Object
) => ActionTree<T, any> {
  return function actions(
    funcName: string,
    key: keyof typeof mutations,
    dataActions = {}
  ): ActionTree<T, any> {
    return {
      [funcName]: ({ commit }, data = {}): Promise<any> => {
        return new Promise((resolve) => {
          commit(mutations[key], {
            ...dataActions,
            ...data,
          })
          resolve({ commit, data })
        })
      },
    }
  }
}
