import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import localforage from 'localforage'
import { IUserInfo } from './intefaces/login'

export default async (
  to: RouteLocationNormalized,
  _: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const isAuthenticated = (await localforage.getItem('userInfo')) as IUserInfo
  if (to.name !== 'Login') {
    if (!isAuthenticated) {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    if (isAuthenticated) {
      next({ name: 'Dashboard' })
    } else {
      next()
    }
  }
}
