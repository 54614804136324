import { MutationTree } from 'vuex'
import { IDashboardAffiliationState } from './states'
import { setMutationType } from '@/stores'

export const dashboardAffiliationMutationTypes = {
  SET_DASHBOARD_DATA: 'SET_DASHBOARD_DATA',
}

const mutations = setMutationType<IDashboardAffiliationState>(
  dashboardAffiliationMutationTypes
)

export const dashboardAffiliationMutations: MutationTree<IDashboardAffiliationState> = {
  ...mutations('SET_DASHBOARD_DATA', 'statisticData'),
}
