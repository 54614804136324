import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import authGuard from './routes-guard'

const DilypseLogin = () => import('./pages/DilypseLogin/DilypseLogin.vue')

const DilypseDashboard = () =>
  import('./pages/DilypseDashboard/DilypseDashboard.vue')

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: DilypseDashboard,
    name: 'Dashboard',
    beforeEnter: authGuard,
  },
  {
    path: '/login',
    component: DilypseLogin,
    name: 'Login',
    beforeEnter: authGuard,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
