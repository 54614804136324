import { ActionTree } from 'vuex'
import { IDashboardResellerState } from './states'
import { affilatesActions } from './affiliates'
import { statisticsActions } from './statistics'
import { productsActions } from './products'

export const dashboardResellerActions: ActionTree<
  IDashboardResellerState,
  any
> = {
  ...statisticsActions,
  ...affilatesActions,
  ...productsActions,
}
