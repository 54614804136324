import { request } from '../http'
import { IAffiliateParams, IProductParams } from '@/intefaces/dashboardReseller'
import { ISortParams } from '@/intefaces/common'
import localforage from 'localforage'
import { IUserInfo } from '@/intefaces/login'

export async function getAffiliationList<T>(
  params: ISortParams & { current_month?: boolean }
) {
  return await request.get<T>('affiliates', {
    ...params,
  })
}

export async function getProductsList<T>(params: ISortParams) {
  return await request.get<T>('products', {
    ...params,
  })
}

export async function getCommissions<T>(params: ISortParams) {
  return await request.get<T>('commissions', {
    ...params,
  })
}
export const getLastCommissions = async (params: ISortParams) => {
  return await request.get('last-commissions', {
    ...params,
  })
}

export const getCurrentCommissions = async (params: ISortParams) => {
  return await request.get('current-commissions', {
    ...params,
  })
}

export const createAffiliate = async (params: IAffiliateParams) => {
  return await request.post('affiliate', {
    ...params,
  })
}

export const updateAffiliate = async (params: IAffiliateParams) => {
  return await request.post('affiliate', {
    ...params,
  })
}
export const updateAffiliateStatus = async (params: IAffiliateParams) => {
  return await request.put('affiliate', {
    ...params,
  })
}

export const createProduct = async (params: IProductParams) => {
  return await request.post('product', {
    ...params,
  })
}
export const updateProductStatus = async (params: Partial<IProductParams>) => {
  return await request.put('product', {
    ...params,
  })
}
